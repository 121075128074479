<template>
  <div class="orders-bg">
    <div class="orders-container container">

      <div class="orders-content">

        <perfect-scrollbar
          class="navi navi-hover scroll mt-4 kitchen-table"
          style="position: relative"
        >
          <div class="w-100 kitchen-table-cells-section">
            <div class="w-100 d-flex flex-wrap" v-if="mealData">

              <div class="kitchen-cell" style="width: 20%" v-for="(meal, name) in mealData" :key="name">
                <div>
                  <p class="text-center mt-4 fw-bold">{{name}}</p>
                  <div class="d-flex justify-content-between flex-wrap" style="margin-left: 30px;" v-if="meal.meal_menu.length > 0">
                    <div class="w-50" v-for="(variant, index) in meal.meal_menu" :key="index">
                      <p :class="['d-inline-block fs-4 pt-1 text-center text-white square-number me-2 number-' + variant.meal_menu_number]" style="width: 40px; height: 40px;">{{variant.meal_menu_number}}</p>
                      <p class="d-inline-block fs-5" v-if="variant.open_orders > 0">{{variant.open_orders}}x</p>
                      <p class="d-inline-block fs-5 text-muted" v-else>-</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </perfect-scrollbar>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import i18n from "@/plugins/vue-i18n";
import moment from "@/plugins/moment";

export default {
  metaInfo () { 
      return { title: i18n.t("meta.kitchen")} 
  },
  data() {
    return {
      mealData: null,
      dateStart: moment(moment()).format("YYYY-MM-DD"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
        getData() {
            this.loading = true;
            ApiService.setHeader();
            ApiService.apiGet("/meal-menu/current-status?date=2021-11-24&food_type_id=1")
            .then((response) => {
                this.mealData = response.data;
                this.loading = false;
            });
        }
  }
};
</script>