<template>
  <div>
    <Header></Header>
    <KitchenView></KitchenView>
    <Footer></Footer>
  </div>
</template>

<style lang="scss">
  // Module styles
  @import "assets/sass/style";
</style>

<script>
import Header from "@/views/core/Header";
import KitchenView from "./views/KitchenView";
import Footer from "@/views/core/Footer";

export default {
  components: {
    Header, KitchenView, Footer
  }
};
</script>